import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import MyModal from "../components/MyModal"
import Header from "../components/Header"

import before1sm from "../images/gallery/sm/before1.jpg"
import before2sm from "../images/gallery/sm/before2.jpg"
import before3sm from "../images/gallery/sm/before3.jpg"
import before4sm from "../images/gallery/sm/before4.jpg"

import after1sm from "../images/gallery/sm/after1.jpg"
import after2sm from "../images/gallery/sm/after2.jpg"
import after3sm from "../images/gallery/sm/after3.jpg"
import after4sm from "../images/gallery/sm/after4.jpg"

import before1lg from "../images/gallery/lg/before1.jpg"
import before2lg from "../images/gallery/lg/before2.jpg"
import before3lg from "../images/gallery/lg/before3.jpg"
import before4lg from "../images/gallery/lg/before4.jpg"

import after1lg from "../images/gallery/lg/after1.jpg"
import after2lg from "../images/gallery/lg/after2.jpg"
import after3lg from "../images/gallery/lg/after3.jpg"
import after4lg from "../images/gallery/lg/after4.jpg"

class Gallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  showBefore1 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={before1lg} alt="Before"></img>})
  }

  showAfter1 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={after1lg} alt="After"></img>})
  }

  showBefore2 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={before2lg} alt="Before"></img>})
  }

  showAfter2 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={after2lg} alt="After"></img>})
  }

  showBefore3 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={before3lg} alt="Before"></img>})
  }

  showAfter3 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={after3lg} alt="After"></img>})
  }

  showBefore4 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={before4lg} alt="Before"></img>})
  }

  showAfter4 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={after4lg} alt="After"></img>})
  }

  modalClose = () => this.setState({showModal: false});

  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <SEO title="Gallery" />
        <Header />
        <section className="container page">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Gallery</h1>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <img src={before1sm} className="img-fluid img-thumbnail" alt="Before" onClick={this.showBefore1}></img>
                  <p className="gallery-image-title">Before</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={after1sm} className="img-fluid img-thumbnail" alt="After" onClick={this.showAfter1}></img>
                  <p className="gallery-image-title">After</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={before2sm} className="img-fluid img-thumbnail" alt="Before" onClick={this.showBefore2}></img>
                  <p className="gallery-image-title">Before</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={after2sm} className="img-fluid img-thumbnail" alt="After" onClick={this.showAfter2}></img>
                  <p className="gallery-image-title">After</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={before3sm} className="img-fluid img-thumbnail" alt="Before" onClick={this.showBefore3}></img>
                  <p className="gallery-image-title">Before</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={after3sm} className="img-fluid img-thumbnail" alt="Before" onClick={this.showAfter3}></img>
                  <p className="gallery-image-title">After</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={before4sm} className="img-fluid img-thumbnail" alt="Before" onClick={this.showBefore4}></img>
                  <p className="gallery-image-title">Before</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <img src={after4sm} className="img-fluid img-thumbnail" alt="Before" onClick={this.showAfter4}></img>
                  <p className="gallery-image-title">After</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <MyModal
          showModal={this.state.showModal}
          onHide={this.modalClose}
          content={this.state.modalContent}
        />
      </Layout>
    )
  }
}

export default Gallery