import React from "react"
import { Modal } from "react-bootstrap"

const MyModal = props => (
    <Modal show={props.showModal} onHide={props.onHide} size="xl">
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer>
            <button className="btn btn-primary" onClick={props.onHide} >
                Close
            </button>
        </Modal.Footer>
    </Modal>
  )

export default MyModal